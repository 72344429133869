import { API_ERROR_CODE } from 'api'
import { TOKEN_ID } from 'config'
import { BASE_FUNNEARN_ENDPOINT } from 'config/constants/endpoints'
import { auth } from 'config/firebase/firebaseConfig'
import { UserInfo } from 'state/games/types'
import { getCookie, removeCookie, setCookie } from 'utils/cookie'

class ServicesAPI {
  static verifyMessageAndLogin = async (email: string, messageHash: string, address: string): Promise<any> => {
    try {
      const uri = `${BASE_FUNNEARN_ENDPOINT}/verifyMessageAndLogin`
      const res = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Origin: `${process.env.NEXT_PUBLIC_FRONT_URL}`,
        },
        body: JSON.stringify({
          email,
          messageHash,
          address,
        }),
      })
      if (res.ok) {
        const json = await res.json()
        if (json.code === 0) {
          return json.data
        }

        return {}
      }
      console.error('verifyMessageAndLogin error', res.statusText)
      return {}
    } catch (e) {
      return {}
    }
  }

  static refeshFirebaseToken = async (): Promise<string | undefined> => {
    try {
      const token = await auth.currentUser?.getIdToken(true)

      return token
    } catch (e) {
      return undefined
    }
  }

  static login = async (walletAddress: string, token: string): Promise<any> => {
    try {
      const uri = `${BASE_FUNNEARN_ENDPOINT}/loginDapp`
      const res = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Origin: `${process.env.NEXT_PUBLIC_FRONT_URL}`,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          walletAddress,
        }),
      })
      if (res.ok) {
        const json = await res.json()
        console.log('Login', json)
        if (json.code === 0) {
          return json.user
        }

        return {}
      }
      const jsonError = await res.json()
      if (jsonError.code === API_ERROR_CODE.TOKEN_EXPIRED) {
        const refeshtToken = await this.refeshFirebaseToken()
        if (refeshtToken) {
          setCookie(TOKEN_ID, refeshtToken)
          return this.login(walletAddress, refeshtToken)
        }
        removeCookie(TOKEN_ID)
        return {
          error: 'Token Expired',
        }
      }

      return {
        error: res.statusText,
      }
    } catch (e) {
      removeCookie(TOKEN_ID)
      return {
        error: 'Token Expired',
      }
      console.error('login error', e)
      return {
        error: e,
      }
    }
  }

  static linkWallet = async (walletAddress: string): Promise<any> => {
    try {
      const token = getCookie(TOKEN_ID)
      if (!token) {
        return {
          err: 'Not Authenticated! Please reload page and try again.',
        }
      }
      const uri = `${BASE_FUNNEARN_ENDPOINT}/linkWallet`
      const res = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Origin: `${process.env.NEXT_PUBLIC_FRONT_URL}`,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          walletAddress,
        }),
      })
      if (res.ok) {
        const json = await res.json()
        return json
      }

      return {
        code: -1,
        err: res.statusText,
      }
    } catch (e) {
      console.error('login error', e)
      return {
        code: -1,
        err: e,
      }
    }
  }

  static getUserInfo = async (): Promise<UserInfo> => {
    try {
      const token = getCookie(TOKEN_ID)

      if (!token) {
        console.error('getUserInfo error1')

        return {} as UserInfo
      }
      const uri = `${BASE_FUNNEARN_ENDPOINT}/getUserInfo`
      const res = await fetch(uri, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Origin: `${process.env.NEXT_PUBLIC_FRONT_URL}`,
          Authorization: `Bearer ${token}`,
        },
      })

      if (res.ok) {
        const json = await res.json()
        if (json.code === 0) {
          return json.user
        }
        console.error('getUserInfo error2')

        return {} as UserInfo
      }
      console.error('getUserInfo error3')

      return {} as UserInfo
    } catch (err) {
      console.error('getUserInfo error4')
      return {} as UserInfo
    }
  }
}

export default ServicesAPI
