/* eslint-disable @typescript-eslint/no-unused-vars */
// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyAK_upqeIQwOrxAdo7-GqSb8JPvesEHb4Y',
  authDomain: 'funnearn-dd2ae.firebaseapp.com',
  databaseURL: 'https://funnearn-dd2ae-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'funnearn-dd2ae',
  storageBucket: 'funnearn-dd2ae.appspot.com',
  messagingSenderId: '868968586277',
  appId: '1:868968586277:web:1c84e64aec1dea9ec533cc',
  measurementId: 'G-WYEZ8GLGWT',
}

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const db = firebase.database()
export const auth = firebase.auth()
export default firebase
