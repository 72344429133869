import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | FunNEarn',
  defaultTitle: 'FunNearn',
  description: 'Earn Crypto while having fun',
  openGraph: {
    title: '🎮 Fun and Earn - Earn Crypto while having fun',
    description: 'Play and Earn Model. Focus on mobile games. Make your fun time more rewarding.',
    images: [{ url: 'https://funn-earn-web.vercel.app/logoWithBg.png' }],
  },
}
