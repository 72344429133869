import { ServicesAPI } from 'api'
import { TOKEN_ID } from 'config'
import { useCallback } from 'react'
import { getCookie } from 'utils/cookie'
import { useAccount, useSignMessage } from 'wagmi'

const useLoginApi = () => {
  const { data, signMessageAsync } = useSignMessage()
  const { address: account } = useAccount()
  const loginAPI = useCallback(async (onLoginSuccess, onLoginFail) => {
    const token = getCookie(TOKEN_ID)
    if (!token) {
      console.log('token', token)
      onLoginFail?.('')
      return
    }

    const loginInfo = await ServicesAPI.login(account, token)
    if (!loginInfo.error) {
      onLoginSuccess?.(loginInfo)
    } else {
      onLoginFail?.(loginInfo.error)
    }
  }, [])

  const logoutAPI = useCallback(async () => {}, [])

  return { loginAPI, logoutAPI }
}

export default useLoginApi
