export const gaugesVotingABI = [
  {
    inputs: [
      { internalType: 'address', name: '_token', type: 'address' },
      { internalType: 'address', name: '_votingEscrow', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'string', name: 'name', type: 'string' },
      { indexed: false, internalType: 'uint256', name: 'type_id', type: 'uint256' },
    ],
    name: 'AddType',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      { indexed: false, internalType: 'uint256', name: 'gauge_type', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'weight', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'pid', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'masterChef', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'chainId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'boostMultiplier', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'maxVoteCap', type: 'uint256' },
    ],
    name: 'NewGauge',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      { indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'weight', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'total_weight', type: 'uint256' },
    ],
    name: 'NewGaugeWeight',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'type_id', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'weight', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'total_weight', type: 'uint256' },
    ],
    name: 'NewTypeWeight',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Unpaused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      { indexed: false, internalType: 'uint256', name: 'pid', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'masterChef', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'chainId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'boostMultiplier', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'maxVoteCap', type: 'uint256' },
    ],
    name: 'UpdateGaugeInfo',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      { indexed: false, internalType: 'uint256', name: 'weight', type: 'uint256' },
    ],
    name: 'VoteForGauge',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      { indexed: false, internalType: 'uint256', name: 'weight', type: 'uint256' },
    ],
    name: 'VoteForGaugeFromAdmin',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address', name: 'gauge_addr', type: 'address' },
      { internalType: 'uint256', name: '_type', type: 'uint256' },
      { internalType: 'uint256', name: '_weight', type: 'uint256' },
      { internalType: 'uint256', name: '_pid', type: 'uint256' },
      { internalType: 'address', name: '_masterChef', type: 'address' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
      { internalType: 'uint256', name: '_boostMultiplier', type: 'uint256' },
      { internalType: 'uint256', name: '_maxVoteCap', type: 'uint256' },
    ],
    name: 'addGauge',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'string', name: '_name', type: 'string' },
      { internalType: 'uint256', name: '_weight', type: 'uint256' },
    ],
    name: 'addType',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'gauge_addr', type: 'address' },
      { internalType: 'uint256', name: 'weight', type: 'uint256' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
    ],
    name: 'changeGaugeWeight',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'type_id', type: 'uint256' },
      { internalType: 'uint256', name: 'weight', type: 'uint256' },
    ],
    name: 'changeTypeWeight',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'checkpoint', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: 'gauge_addr', type: 'address' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
    ],
    name: 'checkpointGauge',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: '', type: 'bytes32' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'gaugeChangesWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'gaugeCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'gaugeIndex_',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'gaugeLastScheduled',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'gaugePointsTotal',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: '', type: 'bytes32' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'gaugePointsWeight',
    outputs: [
      { internalType: 'uint256', name: 'bias', type: 'uint256' },
      { internalType: 'uint256', name: 'slope', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'gauge_addr', type: 'address' },
      { internalType: 'uint256', name: 'time', type: 'uint256' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
    ],
    name: 'gaugeRelativeWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'gauge_addr', type: 'address' },
      { internalType: 'uint256', name: 'time', type: 'uint256' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
    ],
    name: 'gaugeRelativeWeight_write',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'gaugeTypeChangesSum',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'gaugeTypeLastScheduled',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'gaugeTypeNames',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'gaugeTypePointsSum',
    outputs: [
      { internalType: 'uint256', name: 'bias', type: 'uint256' },
      { internalType: 'uint256', name: 'slope', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'gaugeTypePointsWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'gaugeTypeSumLastScheduled',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'gaugeTypes',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'gaugeTypes_',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'gauges',
    outputs: [
      { internalType: 'uint256', name: 'pid', type: 'uint256' },
      { internalType: 'address', name: 'masterChef', type: 'address' },
      { internalType: 'uint256', name: 'chainId', type: 'uint256' },
      { internalType: 'address', name: 'pairAddress', type: 'address' },
      { internalType: 'uint256', name: 'boostMultiplier', type: 'uint256' },
      { internalType: 'uint256', name: 'maxVoteCap', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'gauge_addr', type: 'address' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
      { internalType: 'bool', name: 'inCap', type: 'bool' },
    ],
    name: 'getGaugeWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: 'inCap', type: 'bool' }],
    name: 'getTotalWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_typeId', type: 'uint256' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
    ],
    name: 'getTypeAndChainIdWeightCapped',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_typeId', type: 'uint256' }],
    name: 'getWeightsSumPerType',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'bytes32', name: '', type: 'bytes32' },
    ],
    name: 'lastUserVote',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'token',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalLastScheduled',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'gauge_addr', type: 'address' },
      { internalType: 'uint256', name: '_pid', type: 'uint256' },
      { internalType: 'address', name: '_masterChef', type: 'address' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
      { internalType: 'uint256', name: '_boostMultiplier', type: 'uint256' },
      { internalType: 'uint256', name: '_maxVoteCap', type: 'uint256' },
    ],
    name: 'updateGaugeInfo',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_gauge_addr', type: 'address' },
      { internalType: 'uint256', name: '_user_weight', type: 'uint256' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
      { internalType: 'bool', name: '_skipNative', type: 'bool' },
      { internalType: 'bool', name: '_skipProxy', type: 'bool' },
    ],
    name: 'voteForGaugeWeights',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: '_gauge_addrs', type: 'address[]' },
      { internalType: 'uint256[]', name: '_user_weights', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '_chainIds', type: 'uint256[]' },
      { internalType: 'bool', name: '_skipNative', type: 'bool' },
      { internalType: 'bool', name: '_skipProxy', type: 'bool' },
    ],
    name: 'voteForGaugeWeightsBulk',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_gauge_addr', type: 'address' },
      { internalType: 'uint256', name: '_admin_weight', type: 'uint256' },
      { internalType: 'uint256', name: '_end', type: 'uint256' },
      { internalType: 'uint256', name: '_chainId', type: 'uint256' },
    ],
    name: 'voteFromAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'voteUserPower',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'bytes32', name: '', type: 'bytes32' },
    ],
    name: 'voteUserSlopes',
    outputs: [
      { internalType: 'uint256', name: 'slope', type: 'uint256' },
      { internalType: 'uint256', name: 'power', type: 'uint256' },
      { internalType: 'uint256', name: 'end', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'votingEscrow',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const
