import { useTranslation } from '@pancakeswap/localization'
import { Button, ButtonProps, useModal } from '@pancakeswap/uikit'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { NextLinkFromReactRouter } from '@pancakeswap/widgets-internal'
import { useCheckLogin } from 'hooks/useCheckLogin'
import useLoginApi from 'hooks/useLoginApi'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import LoginModal from './Menu/LoginModal'
import Trans from './Trans'

const StyledButton = styled(Button)`
  min-height:40px;
  margin-right: 5px;
`

const ConnectEmailButton = ({ children, ...props }: ButtonProps) => {
  const { isLogin } = useCheckLogin()
  const [isLogingIn, setIsLogingIn] = useState(false)
  const [hasLogedIn, setHasLogedIn] = useState(false)
  const [userEmail, setUserEmail] = useState('')

  const onLoginSuccessCB = useCallback((userInfo) => {
    dismissLoginModal()
    setHasLogedIn(true)
    setIsLogingIn(false)
    setUserEmail(userInfo.email)
  }, [hasLogedIn, isLogingIn,userEmail])

  const onLoginFailCB = useCallback((error) => {
    setHasLogedIn(false)
    setIsLogingIn(false)
  }, [isLogin,hasLogedIn, isLogingIn,userEmail])

  const { loginAPI, logoutAPI } = useLoginApi()

  const onLoginModalClose = () => {
    dismissLoginModal()
  }

  const [showLoginModal, dismissLoginModal] = useModal(
    <LoginModal
    
      onLoginSuccess={onLoginSuccessCB}
    />,
    true,
    false,
    '',
  )

  const {
    t,
    currentLanguage: { code },
  } = useTranslation()

  const handleClick = () => {
    showLoginModal()
  }

  const redirectToEarn = () => {
  }

  useEffect(() => {
    console.log('isLogin', isLogin, 'hasLogedIn', hasLogedIn, 'isLogingIn', isLogingIn)
    if (isLogin && !hasLogedIn && !isLogingIn) {
      setIsLogingIn(true)
      loginAPI(onLoginSuccessCB,onLoginFailCB)
    }
  }, [isLogin, hasLogedIn, isLogingIn])

  // if (!isLogin)
  return (
    <>
      {!isLogin ? (
        <StyledButton onClick={handleClick} {...props}>
          <Trans>Login</Trans>
        </StyledButton>
      ) : isLogingIn ? (
        <Trans color="text">Authenticating...</Trans>
      ) : 
        hasLogedIn ?(
          <StyledButton  as={NextLinkFromReactRouter}
          to='/earn-dashboard'
          variant="primary"
          {...props}>
            <StyledButton   variant='primary'  {...props}><Trans>Earn</Trans></StyledButton>
          
        </StyledButton>
        ):(
          <StyledButton onClick={handleClick} {...props}>
          <Trans>Login</Trans>
        </StyledButton>
        )
      }
    </>
  )
  // return (
  //   <>HI</>
  // )
}

export default ConnectEmailButton
