import { useWeb3React } from '@pancakeswap/wagmi'
import { TOKEN_ID } from 'config'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { getCookie } from 'utils/cookie'
import useActiveWeb3React from './useActiveWeb3React'

const ROUTE_HARD_CHECK = ['profile', 'teams', 'tournament', 'pve', 'referral']

export const useCheckLogin = () => {
  const { pathname } = useRouter()
  const { chainId } = useActiveWeb3React()
  const { account } = useWeb3React()

  const tokenId = getCookie(TOKEN_ID)
 

  const [isLogin, setIsLogin] = useState(false)

  const validateDataLogin = useMemo(() => {
    return tokenId 
  }, [tokenId])

  useEffect(() => {
    if (validateDataLogin) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  
  }, [validateDataLogin])

  return {
    isLogin,
    validateDataLogin,
  }
}
