import { ContextApi } from '@pancakeswap/localization'
import memoize from 'lodash/memoize'
import { ASSET_CDN } from './endpoints'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'FunNEarn',
  description: 'Play and Earn Model. Focus on mobile games. Make your fun time more rewarding.',
  image: `https://funn-earn-web.vercel.app/logoWithBg.png`,
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string; image?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Home') },
      '/games': { title: t('Games'), image: `${ASSET_CDN}/web/og/nft.jpg` },
      '/games/collections': { basePath: true, title: t('Games Hub'), image: `${ASSET_CDN}/web/og/nft.jpg` },
      '/games/live-tournament': { title: t('Tournament'), image: `${ASSET_CDN}/web/og/nft.jpg` },
      '/earn-dasboard': { basePath: true, title: t('Earn Dashboard') },
      '/faqs': { basePath: true, title: t('FAQs') },
    },
    defaultTitleSuffix: t('Fun And Earn'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
        image: pathMetadata.image,
      }
    }
    return {} as PageMeta
  },
  (path, t, locale) => `${path}#${locale}`,
)
