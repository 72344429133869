import { useTranslation } from '@pancakeswap/localization'
import { Box, Button, Modal, useToast } from '@pancakeswap/uikit'
import { ServicesAPI } from 'api'
import { TOKEN_ID } from 'config'
import firebase from 'config/firebase/firebaseConfig'
import { useCallback, useState } from 'react'
import { useAppDispatch } from 'state'
import styled from 'styled-components'
import { setCookie } from 'utils/cookie'
import { useAccount } from 'wagmi'

const CardIcon = styled.div`
  position: relative;
  top: auto;
  left: auto;
`

const Wrapper = styled(Box)`
  background: linear-gradient(270deg, #000000b3 0, #444444c7 0.01%, #424242d1 105.1%);
  border: 1px solid #52525270;
  border-radius: 12px;
  color: #fff;
  width: 360px;
  height: auto;
  // min-height: 360px;
  @media screen and (max-width: 668px) {
    width: 330px;
  }
`
const Title = styled(Box)`
  font-size: 21px;
  font-weight: 700;
`
const ContainerPopup = styled(Box)`
  padding: 40px 30px 10px 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const LoginModal = ({ onLoginSuccess }) => {
  const dispatch = useAppDispatch()
  const [isLoging, setIsLoging] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const { address: account } = useAccount()

  const { t } = useTranslation()
 
  const onLoginSocialNetWork = useCallback(
    async(type: string) => {
      let provider
      switch (type) {
        case 'facebook':
          provider = new firebase.auth.FacebookAuthProvider()
          break
        case 'apple':
          provider = new firebase.auth.OAuthProvider('apple.com')
          break
        default:
          provider = new firebase.auth.GoogleAuthProvider()
          break
      }
      setIsLoging(true)
      try{
        const result = await firebase.auth().signInWithPopup(provider);
        console.log('result', result)
        const idToken = await result?.user?.getIdToken()
        setCookie(TOKEN_ID, idToken,7*24*60*60)
        const loginInfo: any = await ServicesAPI.login(account,idToken)
        console.log('loginInfo', loginInfo)
        if (!loginInfo.error){
          onLoginSuccess(loginInfo)
          toastSuccess(`Login succeed`)
        }else{
          toastError(`Login Fail`,loginInfo.error)
        }
        
      }catch(error:any){
        console.log('error', error)
        console.log(`Login with ${type} Error`, error.message)
        toastError(`Login with ${type} Error `, error.message)
      }
      setIsLoging(false)
  },[isLoging])

  return (
    <Modal title={t('Login with your email')} headerBackground="gradientCardHeader"  >
      <Button
        disabled={isLoging}
        onClick={() => {
          onLoginSocialNetWork('google')
        }}
        variant="text"
        className="w-full"
        style={{
          marginTop: '20px',
          border: '1px solid #969696',
          backgroundColor: '#454344',
          color: 'white',
          fontWeight: 'normal',
          width: '304px',
          padding: '0px',
          height: '40px',
        }}
        scale="sm"
      >
        <img src="/images/google.svg" alt="logo" style={{ height: '22px', width: 'auto', marginRight: ' 12px' }} />
        Login with Google
      </Button>

      <Button
        disabled={isLoging}
        onClick={() => {
          onLoginSocialNetWork('apple')
        }}
        variant="text"
        className="w-full"
        style={{
          marginTop: '20px',
          border: '1px solid #969696',
          backgroundColor: '#454344',
          color: 'white',
          fontWeight: 'normal',
          width: '304px',
          padding: '0px',
          marginBottom: '30px',
          height: '40px',
        }}
        scale="sm"
      >
        <img src="/images/apple.svg" alt="logo" style={{ height: '22px', width: 'auto', marginRight: ' 12px' }} />
        Login with Apple
      </Button>
    </Modal>
  )
}

export default LoginModal
